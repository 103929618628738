import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import device from "../../deviceQueries"

const StyledImage = styled(Img)`
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
  height: 100%;
  width: auto;

  @media ${device.tablet} {
    clip-path: polygon(0 0, 100% 0, 100% 84%, 50% 100%, 0 84%);
  }
`

const BackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundMobile: file(
        relativePath: { eq: "first-section-background-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundDesktop: file(
        relativePath: { eq: "first-section-background-desktop.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    data.backgroundMobile.childImageSharp.fluid,
    {
      ...data.backgroundDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return <StyledImage fluid={sources} />
}

export default BackgroundImage
