import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import device from "../../deviceQueries"
import Icon from "./icon"
import { gsap } from "gsap"

const Wrapper = styled.article`
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 30px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.laptop} {
    width: 1100px;
  }
`

const Container = styled.div`
  box-sizing: border-box;
  background-color: white;

  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 110px;
  height: 110px;
  margin: 15px;

  @media ${device.mobileM} {
    width: 130px;
    height: 130px;
  }

  @media ${device.mobileL} {
    width: 160px;
    height: 160px;
  }

  @media ${device.tablet} {
    width: 290px;
    height: 290px;
    margin: 25px;
    padding: 20px;
  }
  svg {
    height: 80%;
    width: auto;
  }
  p {
    font-size: 1.7rem;
    font-weight: 300;
    margin-bottom: 0;
  }
`

const IconsGrid = () => {
  const wrapperRef = useRef()
  useEffect(() => {
    const { children: childrens } = wrapperRef.current

    gsap.from(childrens, {
      scrollTrigger: {
        trigger: wrapperRef.current,
        scrub: 1,
        start: "top 90%",
        end: "top 60%",
      },
      y: "+=150",
      opacity: 0.2,
      scale: 0.8,
    })
  }, [wrapperRef])
  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        <Icon name="klema" />
        <p>Klemy</p>
      </Container>
      <Container>
        <Icon name="lacznik" />
        <p>Łączniki szyn</p>
      </Container>
      <Container>
        <Icon name="uchwyt" />
        <p>Uchwyty solarne</p>
      </Container>
      <Container>
        <Icon name="trojkat" />
        <p>Trójkąty montażowe</p>
      </Container>
      <Container>
        <Icon name="sruba" style={{ transform: "scale(1,-1)" }} />
        <p>Śruby i nakrętki</p>
      </Container>
      <Container>
        <Icon name="kotwa" />
        <p>Kotwy fundamentowe</p>
      </Container>
    </Wrapper>
  )
}

export default IconsGrid
