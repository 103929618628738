import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import BoltPhoto from "./boltPhoto"
import ConstructionPhoto from "./constructionPhoto"
import YellowAssetContainer from "./yellowAsset"
import RightSideAsset from "./rightSideAsset"
import device from "../../deviceQueries"
import { gsap } from "gsap"

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding-bottom: 30px;

  @media ${device.tablet} {
    padding-bottom: 70px;
  }
`

const PhotoSection = () => {
  const yellowRef = useRef()
  const photoRef = useRef()
  const secondPhotoRef = useRef()
  const rightAssetRef = useRef()

  useEffect(() => {
    if (window.innerWidth > 800) {
      const tl = gsap.timeline({
        scrollTrigger: {
          start: "top 90%",
          end: "top 60%",
          trigger: photoRef.current,
          scrub: 0.8,
        },
      })
      tl.from(
        [photoRef.current, secondPhotoRef.current],
        {
          y: "+=200",
        },
        0
      )
        .from(
          yellowRef.current,
          {
            y: "+=800",
          },
          0
        )
        .from(
          rightAssetRef.current,
          {
            y: "+=400",
          },
          0
        )
    }
  }, [])
  return (
    <StyledSection>
      <YellowAssetContainer ref={yellowRef} style={{ position: "absolute" }} />
      <BoltPhoto ref={photoRef} />
      <ConstructionPhoto ref={secondPhotoRef} />
      <RightSideAsset ref={rightAssetRef} style={{ position: "absolute" }} />
    </StyledSection>
  )
}

export default PhotoSection
