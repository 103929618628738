import React from "react"

import VerticalYellowHex from "../../assets/vertical-yellow-hex.svg"
import styled from "styled-components"
import device from "../../deviceQueries"

const YellowAsset = styled(VerticalYellowHex)`
  width: 70px;
  height: auto;
  opacity: 0.45;

  @media ${device.laptop} {
    width: 110px;
  }
`

const Container = styled.div`
  position: absolute;
  z-index: -2;
  left: 0;
  bottom: 300px;
`

const YellowAssetContainer = React.forwardRef((props, ref) => (
  <Container ref={ref}>
    <YellowAsset />
  </Container>
))

export default YellowAssetContainer
