import React from "react"

import Klema from "../../assets/grid/klema.svg"
import Lacznik from "../../assets/grid/lacznik.svg"
import Sruba from "../../assets/grid/sruba.svg"
import Trojkat from "../../assets/grid/trojkat.svg"
import Uchwyt from "../../assets/grid/uchwyt.svg"
import Kotwa from "../../assets/grid/kotwa.svg"

const IconCollection = {
  klema: Klema,
  lacznik: Lacznik,
  sruba: Sruba,
  trojkat: Trojkat,
  uchwyt: Uchwyt,
  kotwa: Kotwa,
}

const Icon = ({ name = "", style = {} }) => {
  const Renderer = IconCollection[name]
  if (!Renderer) return <div />
  return <Renderer {...style} />
}

export default Icon
