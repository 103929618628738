import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import device from "../../deviceQueries"
import { gsap } from "gsap"

const Wrapper = styled.div`
  margin: auto;
  padding-top: 40px;
  display: inline-flex;
  justify-content: space-around;
  width: 90%;
`

const StyledLink = styled.a`
  text-decoration: none;
  width: 45%;

  @media ${device.mobileL} {
    width: 180px;
  }
  @media ${device.laptop} {
    width: 230px;
  }
`

export const Button = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 15px;
  font-size: 1.7rem;
  outline: none;

  ${props =>
    props.orange &&
    css`
      background-color: rgba(255, 90, 0, 0.7);
      color: white;
    `}

  ${props =>
    props.lime &&
    css`
      background-color: rgba(249, 249, 33, 0.3);
      color: black;
    `}
`
const Buttons = () => {
  const orangeRef = useRef()
  const yellowRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: orangeRef.current,
        scrub: 1.6,
        start: "top 75%",
        end: "+=0",
      },
    })

    tl.fromTo(
      orangeRef.current,
      { x: "+=20" },
      {
        backgroundColor: "rgb(255, 90, 0)",
        x: "0",
      },
      0
    ).fromTo(
      yellowRef.current,
      {
        x: "-=20",
      },
      {
        backgroundColor: "rgb(249, 249, 33)",
        x: 0,
      },
      0
    )
  }, [])
  return (
    <Wrapper>
      <StyledLink target="_blank" href="/albamet_katalog.pdf">
        <Button ref={yellowRef} lime>
          Pobierz katalog
        </Button>
      </StyledLink>

      <StyledLink target="_blank" href="https://allegro.pl/uzytkownik/albamet">
        <Button ref={orangeRef} orange>
          Kup teraz
        </Button>
      </StyledLink>
    </Wrapper>
  )
}

export default Buttons
