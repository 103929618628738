import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import device from "../../deviceQueries"

const StyledImage = styled(Img)`
  /* max-height: 400px;

  @media ${device.laptop} {
    max-height: 800px;
  } */
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      garageMobile: file(relativePath: { eq: "garage-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      garageDesktop: file(relativePath: { eq: "garage.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    data.garageMobile.childImageSharp.fluid,
    {
      ...data.garageDesktop.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return <StyledImage fluid={sources} />
}
