import styled from "styled-components"
import Img from "gatsby-image"
import device from "../../deviceQueries"

export const StyledImage = styled(Img)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const StyledPhotoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 47vw;
  height: 47vw;
  margin: 1%;

  @media ${device.mobileL} {
    width: 42vw;
    height: 42vw;
    margin: 3%;
  }

  @media ${device.laptop} {
    margin: 15px;

    width: 450px;
    height: 450px;
    margin: 20px;
  }

  @media ${device.laptopL} {
    width: 600px;
    height: 600px;
    margin: 26px;
  }

  h2 {
    color: white;
    text-align: center;
    font-weight: 400;
    font-size: 2rem;

    @media ${device.tablet} {
      font-size: 3.6rem;
    }
  }
`

export default StyledPhotoContainer
