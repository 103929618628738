import React from "react"
import styled from "styled-components"
import ContactHeading from "./contactHeading"
import ContactForm from "./contactForm"

const StyledSection = styled.section`
  padding-bottom: 100px;
`

const Contact = () => (
  <StyledSection id="contact">
    <ContactHeading />
    <ContactForm />
  </StyledSection>
)

export default Contact
