import React from "react"

import styled from "styled-components"
import BackgroundImage from "./backgroundImage"

const Section = styled.section`
  width: 100%;
  height: 120vh;
`

const FirstSection = () => (
  <Section>
    <BackgroundImage />
  </Section>
)

export default FirstSection
