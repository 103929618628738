import React, { forwardRef } from "react"
import StyledPhotoContainer, { StyledImage } from "./styledPhotoContainer"
import { useStaticQuery, graphql } from "gatsby"

const ConstructionPhoto = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "construction-photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledPhotoContainer ref={ref}>
      <StyledImage
        style={{ position: "absolute" }}
        fluid={data.file.childImageSharp.fluid}
      />
      <h2>
        Konstrukcje
        <br />
        stalowe
      </h2>
    </StyledPhotoContainer>
  )
})

export default ConstructionPhoto
