import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FirstSection from "../components/firstSection"
import WhoSection from "../components/whoSection"
import PhotoSection from "../components/photoSection"
import OfferSection from "../components/offerSection"
import AllegroSection from "../components/allegroSection"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Albamet - aluminiowe i stalowe elementy montażowe" />
    <FirstSection />
    <WhoSection />
    <PhotoSection />
    <OfferSection />
    <AllegroSection />
    <Contact />
  </Layout>
)

export default IndexPage
