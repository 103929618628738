import React from "react"
import styled from "styled-components"
import device from "../../deviceQueries"

const Heading = styled.div`
  text-align: center;
  color: rgb(1, 119, 139);

  margin-top: 40px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  h2 {
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 0;
  }

  p {
    margin-top: 10px;
    font-size: 2rem;
    font-weight: 300;
  }
`

const ContactHeading = () => (
  <Heading>
    <h2>Napisz do nas!</h2>
    <p>Przygotujemy dla Ciebie ofertę</p>
  </Heading>
)

export default ContactHeading
