import React, { forwardRef } from "react"
import RightSideHex from "../../assets/small-hex-background.svg"
import styled from "styled-components"
import device from "../../deviceQueries"

const StyledRightSideAsset = styled(RightSideHex)`
  display: none;
  height: auto;
  opacity: 0.1;

  @media ${device.tablet} {
    display: initial;
    width: 230px;
  }

  @media ${device.laptop} {
    width: 270px;
  }

  @media ${device.laptopL} {
    width: 320px;
  }
`

const Container = styled.div`
  position: absolute;
  z-index: -2;
  right: 0;
  bottom: 30%;
`

const RightSideAsset = forwardRef((props, ref) => (
  <Container ref={ref}>
    <StyledRightSideAsset />
  </Container>
))

export default RightSideAsset
