import React, { forwardRef } from "react"
import StyledPhotoContainer, { StyledImage } from "./styledPhotoContainer"
import { useStaticQuery, graphql } from "gatsby"

const BoltPhoto = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bolt-photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledPhotoContainer ref={ref}>
      <StyledImage
        style={{ position: "absolute" }}
        fluid={data.file.childImageSharp.fluid}
      />
      <h2>
        Elementy
        <br />
        montażowe
      </h2>
    </StyledPhotoContainer>
  )
})

export default BoltPhoto
