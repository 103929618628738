import React, { useState } from "react"
import styled from "styled-components"
import GalleryView from "./galleryView"

const Wrapper = styled.div`
  text-align: center;
`

const Button = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 15px;
  font-size: 1.7rem;
  outline: none;
  background-color: rgb(249, 249, 33);
  color: black;
  width: 300px;
  margin: auto;
  margin-top: 20px;
  @media (min-width: 800px) {
    transform: translateY(-100%);
    margin-bottom: 40px;
  }
`

const Gallery = () => {
  const [isOpened, setOpened] = useState(false)
  return (
    <Wrapper>
      <Button onClick={() => setOpened(!isOpened)}>
        Zobacz nasze realizacje
      </Button>
      {isOpened ? <GalleryView closeAction={() => setOpened(false)} /> : null}
    </Wrapper>
  )
}

export default Gallery
