import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  animation: show 0.8s ease-out;
  background-color: transparent;

  h2 {
    font-weight: 300;
    font-size: 2.6rem;
    color: rgb(1, 119, 139);
  }

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(50px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const QuestionSent = () => (
  <StyledWrapper>
    <h2>Dziękujemy za wysłanie zapytania.</h2>
  </StyledWrapper>
)

export default QuestionSent
