import styled from "styled-components"
import device from "../../deviceQueries"

const Article = styled.article`
  width: 96%;
  margin: auto;
  text-align: center;
  padding: 30px 40px;
  background-color: white;
  box-shadow: 0px 5px 26px -9px rgba(0, 0, 0, 0.4);
  position: relative;

  @media ${device.mobileL} {
    width: 90%;
  }

  @media ${device.tablet} {
    width: 600px;
  }

  @media ${device.laptopL} {
    width: 750px;
  }

  @media ${device.desktop} {
    width: 1050px;
    padding: 55px;
  }

  h1 {
    color: rgb(0, 104, 126);
    font-weight: 400;
    font-size: 2.1rem;
  }

  p {
    color: black;
    font-size: 1.6rem;
    font-weight: 300;
  }
`

export default Article
