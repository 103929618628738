import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import Article from "../styledContainers/article"
import device from "../../deviceQueries"
import IconsGrid from "./iconsGrid"
import Gallery from "./gallery"
import { gsap } from "gsap"

const CustomArticle = styled(Article)`
  @media ${device.laptop} {
    padding-bottom: 60px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(1, 120, 140);
  clip-path: polygon(50% 0%, 100% 6%, 100% 94%, 50% 100%, 0 94%, 0 6%);

  width: 100%;
  height: 100%;
  z-index: -1;

  @media ${device.tablet} {
    clip-path: polygon(50% 0%, 100% 15%, 100% 85%, 50% 100%, 0 85%, 0 15%);
  }
`

const StyledSection = styled.section`
  position: relative;
  padding-bottom: 100px;

  @media ${device.laptop} {
    padding-bottom: 230px;
  }
`

const OfferSection = () => {
  const descriptionRef = useRef()

  useEffect(() => {
    if (window.innerWidth > 800) {
      gsap.to(descriptionRef.current, {
        scrollTrigger: {
          trigger: descriptionRef.current,
          start: "top 80%",
          end: "+=200",
          scrub: 1.6,
        },
        yPercent: "-=50",
        opacity: 1,
        ease: "power2.out",
      })
    }
  }, [])
  return (
    <StyledSection>
      <Background />
      <CustomArticle id="offer" ref={descriptionRef}>
        <h1>Oferta</h1>
        <p>
          W naszym asortymencie znajdują się komponenty montażowe niezbędne przy
          instalacji paneli słonecznych oraz wytrzymałe konstrukcje, stanowiące
          podstawę dla ogniw fotowoltaicznych. Gwarantujemy indywidualne
          podejście do każdego klienta.
        </p>
      </CustomArticle>
      <Gallery />
      <IconsGrid />
    </StyledSection>
  )
}

export default OfferSection
