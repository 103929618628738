import React, { useRef, useEffect, useState, useCallback } from "react"
import styled, { css } from "styled-components"
import { gsap } from "gsap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import CloseButton from "./closeButton"
import device from "../../deviceQueries"

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 30;
  text-align: center;
  overflow-y: auto;
`

const ImagesWrapper = styled.div`
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0;
  background-color: white;
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  overflow-y: auto;

  @media ${device.tablet} {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
  }

  ${props =>
    props.mode === "grid" &&
    css`
      padding: 30px;
      justify-content: center;
      align-items: flex-start;
    `}

  ${props =>
    props.mode === "single" &&
    css`
      justify-content: center;
      align-items: center;
    `}
`

const StyledImage = styled(Img)`
  max-height: 200px;

  width: 200px;
  height: 200px;
  position: relative;
  margin: 10px;
  cursor: pointer;

  @media ${device.mobileM} {
    width: 260px;
  }

  @media ${device.mobileL} {
    width: 300px;
  }

  @media ${device.tablet} {
    width: 200px;
  }

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }
  :hover {
    ::after {
      opacity: 1;
    }
  }
`

const SingleImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArrowButton = styled.div`
  position: absolute;
  font-size: 40px;
  top: 50%;
  cursor: pointer;
  z-index: 4;
  color: rgb(59, 116, 139);
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  padding-left: 13px;
  padding-right: 13px;
  display: flex;

  ${props =>
    props.left &&
    css`
      left: 14px;
    `}

  ${props =>
    props.right &&
    css`
      right: 14px;
    `}
`

const GalleryView = ({ closeAction }) => {
  const data = useStaticQuery(graphql`
    query {
      GalleryImages: allFile(
        filter: { absolutePath: { regex: "src/gallery/" } }
      ) {
        edges {
          node {
            id
            name
            small: childImageSharp {
              fluid(maxHeight: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            big: childImageSharp {
              fluid(maxHeight: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const wrapperRef = useRef()
  const imagesWrapperRef = useRef()
  const [contentAvailable, setAvailable] = useState(false)
  const [selectedImage, setImage] = useState(null)

  useEffect(() => {
    const tl = gsap.timeline()
    tl.fromTo(
      wrapperRef.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        duration: 0.6,
        opacity: 1,
        onComplete: () => setAvailable(true),
      }
    )
  }, [])

  useEffect(() => {
    if (contentAvailable) {
      gsap.to(imagesWrapperRef.current, {
        opacity: 1,
      })
    }
  }, [contentAvailable])

  const close = useCallback(() => {
    if (selectedImage) {
      setImage(null)
      return
    }
    const tl = gsap.timeline()
    tl.to(imagesWrapperRef.current, {
      opacity: 0,
      duration: 0.3,
    }).to(wrapperRef.current, {
      opacity: 0,
      duration: 0.4,
      onComplete: closeAction,
    })
  }, [selectedImage, closeAction])

  const changePhoto = useCallback(
    num => {
      const { edges: images } = data.GalleryImages
      const oldIndex = images.findIndex(
        image => image.node.id === selectedImage
      )

      switch (num) {
        case -1:
          if (oldIndex === 0) {
            setImage(images[images.length - 1].node.id)
          } else {
            setImage(images[oldIndex + -1].node.id)
          }
          break
        case 1:
          if (oldIndex + 1 === images.length) {
            setImage(images[0].node.id)
          } else {
            setImage(images[oldIndex + 1].node.id)
          }
          break

        default:
          break
      }
    },
    [selectedImage, data.GalleryImages]
  )

  return (
    <Wrapper ref={wrapperRef}>
      {contentAvailable && (
        <ImagesWrapper
          ref={imagesWrapperRef}
          mode={selectedImage ? "single" : "grid"}
        >
          <CloseButton onClick={close} />

          {!selectedImage ? (
            data.GalleryImages.edges.map(({ node }) => (
              <div
                key={node.id}
                style={{ display: "inline-block" }}
                onClick={() => setImage(node.id)}
              >
                <StyledImage fluid={node.small.fluid} />
              </div>
            ))
          ) : (
            <React.Fragment>
              <ArrowButton onClick={() => changePhoto(-1)} left>
                {"<"}
              </ArrowButton>
              <ArrowButton onClick={() => changePhoto(1)} right>
                {">"}
              </ArrowButton>
              <SingleImage>
                <Img
                  style={{ maxWidth: "100%", width: "100%", maxHeight: "100%" }}
                  fadeIn={true}
                  durationFadeIn={1000}
                  fluid={
                    data.GalleryImages.edges.find(
                      item => item.node.id === selectedImage
                    ).node.big.fluid
                  }
                />
              </SingleImage>
            </React.Fragment>
          )}
        </ImagesWrapper>
      )}
    </Wrapper>
  )
}

export default GalleryView
