import React, { useState, useRef, useCallback } from "react"
import styled, { css } from "styled-components"
import { Formik, Field } from "formik"
import device from "../../deviceQueries"
import validate from "./validate"
import Reaptcha from "reaptcha"
import axios from "axios"
import QuestionSent from "./questionSent"
import ErrorForm from "./errorForm"

const Form = styled.form`
  display: grid;
  grid-row: 40% 60%;
  background-color: white;
  box-shadow: 4px 6px 30px -10px rgba(89, 89, 89, 0.3);
  width: 90%;
  margin: auto;
  padding: 40px 20px;

  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media ${device.mobileL} {
    width: 80%;
  }

  @media ${device.tablet} {
    width: 65%;
  }

  @media ${device.laptop} {
    grid-template-columns: 30% 70%;
    grid-template-rows: auto;
    max-width: 800px;
  }
`

const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 50px;

  @media ${device.laptop} {
    padding-left: 65px;
    padding-top: 0px;
  }
`
const Agreements = styled.div`
  padding-top: 40px;
`
const SendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  @media ${device.laptop} {
    flex-direction: row;
    padding-top: 40px;
    padding-left: 65px;
    justify-content: flex-end;
    align-items: flex-end;
  }

  button {
    background-color: rgb(247, 250, 88);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 4px;
    font-size: 1.4rem;
    transition: background-color 0.3s, color 0.3s;

    :disabled {
      background-color: rgb(1, 120, 140);
      color: white;
    }
  }
`

const InputBase = styled.input`
  position: relative;
  padding: 11px 18px;
  border-radius: 4px;
  border-color: rgb(236, 236, 236);
  border-width: 2px;
  border-style: solid;
  outline: none;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 300;
  width: 100%;
  transition: border-color 0.4s ease;
  position: relative;
  font-family: inherit;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  -moz-appearance: textfield; /* Firefox */

  &::placeholder {
    color: rgb(158, 158, 158);
  }

  margin-top: 18px;

  @media ${device.laptop} {
    margin-top: 0;
  }

  ${props =>
    props.error &&
    css`
      border-color: red;
    `}

  ${props =>
    props.textarea &&
    css`
      height: 280px;
      resize: none;
      @media ${device.laptop} {
        height: 320px;
      }
    `}
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledInput = ({
  value,
  error,
  id,
  type,
  placeholder,
  onChange,
  component = "input",
}) => (
  <InputWrapper>
    <InputBase
      textarea={component === "textarea"}
      as={component}
      id={id}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      error={error}
    />
    {error && <Label htmlFor={id}>{error}</Label>}
  </InputWrapper>
)

const Label = styled.label`
  color: red;
  width: 100%;
  font-size: 0.8rem;
  position: absolute;
  font-size: 1.1rem;

  top: 4px;
  left: 4px;

  @media ${device.mobileL} {
    top: 0px;
    left: 4px;
  }

  @media ${device.laptop} {
    font-size: 0.8rem;

    top: -17px;
    left: 6px;
  }
`

const ContactLabel = styled.label`
  position: absolute;
  bottom: -24px;
  left: 4px;
  color: red;
  width: 120%;
  font-size: 1.1rem;
  @media ${device.laptop} {
    bottom: -25px;
    left: 6px;
    font-size: 0.9rem;
    left: 10px;
  }
`

const StyledCheckInput = styled(Field)`
  display: none;
`

const StyledCheckLabel = styled.label`
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.3rem;
  font-weight: 300;
  position: relative;
  display: block;
  padding-left: 18px;
  cursor: pointer;
  overflow: visible;
  a {
    color: rgb(1, 119, 139);
  }

  :before {
    content: "";
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    left: -5px;
    top: 1px;
    width: 18px;
    height: 18px;
    color: transparent;
    transition: 0.2s;
    overflow: visible;
    color: rgba(0, 0, 0, 0.5);
  }

  @media ${device.laptop} {
    font-size: 1rem;
  }

  ${props =>
    props.error &&
    css`
      :before {
        border-color: red;
      }
    `}

  ${props =>
    props.checked &&
    css`
      :before {
        content: "✔";
        border-color: rgba(0, 0, 0, 0.4);
      }
    `}

    ${props =>
      props.hideCheckbox &&
      css`
        cursor: unset;
        :before {
          display: none;
        }
      `}
`

const CheckboxField = ({ children, value, error, id, name }) => {
  return (
    <>
      <StyledCheckInput id={id} type="checkbox" name={name} />
      <StyledCheckLabel
        error={error ? true : false}
        htmlFor={id}
        checked={value}
      >
        {children}
      </StyledCheckLabel>
    </>
  )
}

const StyledRecaptcha = styled(Reaptcha)`
  .grecaptcha-badge {
    visibility: hidden;
    position: absolute;
  }
`

const FormStates = {
  initial: "initial",
  sending: "sending",
  emailSent: "emailSent",
  error: "error",
}

const ContactForm = () => {
  const captchaRef = useRef(null)
  let data = {}
  const [formState, setFormState] = useState(FormStates.initial)

  const captchaTrigger = useCallback(
    values => {
      if (captchaRef !== null) {
        data = values
        captchaRef.current.execute()
        setFormState(FormStates.sending)
      }
    },
    [captchaRef]
  )

  const sendEmail = token => {
    axios
      .post(
        "https://us-central1-albamet-contact-form.cloudfunctions.net/sendEmail",
        { token: token, email: data }
      )
      .then(res => {
        setFormState(FormStates.emailSent)
      })
      .catch(err => {
        setFormState(FormStates.error)
      })
  }

  if (formState === FormStates.error) {
    return <ErrorForm />
  }

  if (formState === FormStates.emailSent) {
    return <QuestionSent />
  }
  const buttonCopy = formState === FormStates.sending ? "Wysyłanie" : "Wyślij"
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        question: "",
        rodo: false,
        dataProcess: false,
      }}
      validate={validate}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={captchaTrigger}
    >
      {props => (
        <Form onSubmit={props.handleSubmit}>
          <FirstSection>
            <StyledInput
              id="firstName"
              type="text"
              placeholder="Imię"
              onChange={props.handleChange}
              value={props.values.firstName}
              error={props.errors.firstName}
            />
            <StyledInput
              id="lastName"
              type="text"
              placeholder="Nazwisko"
              onChange={props.handleChange}
              value={props.values.lastName}
              error={props.errors.lastName}
            />
            <StyledInput
              id="companyName"
              type="text"
              placeholder="Nazwa firmy"
              onChange={props.handleChange}
              value={props.values.companyName}
              error={props.errors.companyName}
            />
            <StyledInput
              id="email"
              type="text"
              placeholder="E-mail"
              onChange={props.handleChange}
              value={props.values.email}
              error={props.errors.email}
            />
            <StyledInput
              id="phone"
              type="number"
              placeholder="Nr telefonu"
              onChange={props.handleChange}
              value={props.values.phone}
              error={props.errors.phone}
            />
            {props.errors.contact && (
              <ContactLabel>{props.errors.contact}</ContactLabel>
            )}
          </FirstSection>
          <SecondSection>
            <StyledInput
              component="textarea"
              id="question"
              name="question"
              onChange={props.handleChange}
              value={props.values.question}
              placeholder={"Treść zapytania"}
              error={props.errors.question}
            />
          </SecondSection>

          <Agreements>
            <CheckboxField
              id="rodo"
              type="checkbox"
              name="rodo"
              onChange={props.handleChange}
              value={props.values.rodo}
              error={props.errors.rodo}
            >
              Akceptuję{" "}
              <a href="/Klauzula-informacyjna-RODO.pdf">Politykę Prywatności</a>
            </CheckboxField>

            <CheckboxField
              id="dataProcess"
              type="checkbox"
              name="dataProcess"
              value={props.values.dataProcess}
              error={props.errors.dataProcess}
            >
              Wyrażam zgodę na przetwarzanie moich danych w celach kontaktowych
            </CheckboxField>

            <StyledCheckLabel hideCheckbox>
              Strona jest chroniona przez reCAPTCHA i Google{" "}
              <a href="https://policies.google.com/privacy">
                Polityka prywatności
              </a>{" "}
              i <a href="https://policies.google.com/terms">Warunki usługi</a>
            </StyledCheckLabel>
          </Agreements>
          <SendButton>
            <StyledRecaptcha
              ref={captchaRef}
              sitekey="6LcqRfoUAAAAAISGGAKNL2aoDDXAvfCdCjQin54f"
              onLoad={() => {}}
              onVerify={token => sendEmail(token)}
              size="invisible"
              onError={() => {}}
              onExpire={() => {}}
              onSubmit={() => {}}
              badge="inline"
            />

            <button disabled={formState === FormStates.sending} type="submit">
              {buttonCopy}
            </button>
          </SendButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
