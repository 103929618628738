import React from "react"
import styled from "styled-components"

const CloseButton = styled.div`
  position: fixed;
  color: rgb(59, 116, 139);
  top: 12%;
  right: 12%;
  cursor: pointer;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  padding: 15px;
  display: flex;
`

const Icon = props => (
  <CloseButton {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      style={{ fill: "rgb(59, 116, 139" }}
      viewBox="0 0 512 512.001"
      xmlSpace="preserve"
    >
      <path d="M284.284,256L506.142,34.142c7.811-7.81,7.811-20.474,0-28.284c-7.811-7.811-20.474-7.811-28.284,0L256,227.716    L34.142,5.858c-7.811-7.811-20.474-7.811-28.284,0c-7.811,7.81-7.811,20.474,0,28.284L227.716,256L5.858,477.858    c-7.811,7.811-7.811,20.474,0,28.284c7.81,7.81,20.473,7.811,28.284,0L256,284.284l221.858,221.858    c7.81,7.81,20.473,7.811,28.284,0c7.811-7.811,7.811-20.474,0-28.284L284.284,256z" />
    </svg>
  </CloseButton>
)

export default Icon
