import React from "react"
import styled from "styled-components"
import AllegroLogo from "./allegroLogo"
import Buttons from "./buttons"
import device from "../../deviceQueries"
import GarageImage from "./garageImage"

const ImageLink = styled.a`
  width: 200px;

  @media ${device.tablet} {
    width: 250px;
  }
`

const StyledSection = styled.section`
  text-align: center;
  padding-top: 40px;

  @media ${device.tablet} {
    padding-top: 120px;
  }
`

const Grid = styled.div`
  display: grid;
  margin: auto;
  margin-bottom: 40px;
  width: 100%;
  overflow-x: hidden;

  @media ${device.tablet} {
    margin-bottom: 120px;
  }
  @media ${device.laptop} {
    width: 800px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const AllegroSection = () => (
  <StyledSection>
    <Grid>
      <Row>
        <ImageLink target="_blank" href="https://allegro.pl/uzytkownik/albamet">
          <AllegroLogo />
        </ImageLink>
      </Row>
      <Row>
        <Buttons />
      </Row>
    </Grid>
    <GarageImage />
  </StyledSection>
)

export default AllegroSection
