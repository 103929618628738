import * as Yup from "yup"

const emailSchema = Yup.string().email().nullable()

const phoneSchema = Yup.number().nullable().min(100000000).max(999999999)

const shortStringSchema = Yup.string().max(50)

const stringSchema = Yup.string()

const validate = ({
  email,
  lastName,
  firstName,
  question,
  companyName,
  phone,
  rodo,
  dataProcess,
}) => {
  const errors = {}

  if (!phone && !email) {
    errors.contact = "Wymagany adres email lub numer telefonu"
  } else {
    if (!phoneSchema.isValidSync(phone) && phone)
      errors.phone = "Nieprawidłowy numer telefonu"

    if (!emailSchema.isValidSync(email) && email)
      errors.email = "Nieprawidłowy adres email"
  }

  if (!firstName) {
    errors.firstName = "Imię jest wymagane"
  } else {
    if (!shortStringSchema.isValidSync(firstName))
      errors.firstName = "Maksymalnie 50 znaków"
  }

  if (!lastName) {
    errors.lastName = "Nazwisko jest wymagane"
  } else {
    if (!shortStringSchema.isValidSync(lastName))
      errors.lastName = "Maksymalnie 50 znaków"
  }

  if (!shortStringSchema.isValidSync(companyName))
    errors.companyName = "Maksymalnie 50 znaków"

  if (!question) {
    errors.question = "Pytanie jest wymagane"
  } else {
    if (!stringSchema.min(20).isValidSync(question))
      errors.question = "Pytanie musi zawierać minimum 20 znaków"

    if (!stringSchema.max(2000).isValidSync(question))
      errors.question = "Pytanie może zawierać maksymalnie 2000 znaków"
  }

  if (!rodo) {
    errors.rodo = "Must have rodo"
  }

  if (!dataProcess) {
    errors.dataProcess = "Must have data process"
  }

  return errors
}
export default validate
