import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import device from "../../deviceQueries"
import HexBackground from "../../assets/who-hex-background.svg"
import Article from "../styledContainers/article"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const StyledSection = styled.section`
  padding-top: 10vw;
  padding-bottom: 7vw;
  position: relative;
`

const StyledHexBackground = styled(HexBackground)`
  position: absolute;
  z-index: -1;
  left: -30%;
  opacity: 1;
  height: auto;
  width: 400px;
  top: -10%;
  display: none;

  @media ${device.tablet} {
    width: 500px;
    display: initial;
  }

  @media ${device.laptop} {
    top: -20%;
    width: 650px;
  }

  @media ${device.laptopL} {
    width: 750px;
  }
`

const YellowTrapezoid = styled.div`
  background-color: rgb(249, 249, 163);

  z-index: -1;
  position: absolute;
  right: 0;
  top: 35%;
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 74%);
  width: calc(20vw);
  height: calc(20vw);

  display: none;

  @media ${device.tablet} {
    display: initial;

    width: 500px;
  }

  @media ${device.laptop} {
    width: calc(90px + 4vw);
    height: calc(235px + 6vw);
  }
`

const WhoSection = () => {
  const hexRef = useRef()
  const yellowHexRef = useRef()

  useEffect(() => {
    if (window.innerWidth > 800) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: hexRef.current,
          start: "top 60%",
          end: "+=100",
          scrub: 3,
          id: "hex_article",
        },
      })

      tl.from(hexRef.current.children[0], {
        y: "+=30",
        x: "+=100",
        opacity: 0,
      })
        .delay(1)
        .from(yellowHexRef.current, {
          width: 0,
          opacity: 0,
          x: "+=50",
          y: "+=300",
        })
    }
  }, [hexRef, yellowHexRef])

  return (
    <StyledSection>
      <Article ref={hexRef}>
        <StyledHexBackground />
        <h1>Kim jesteśmy?</h1>
        <p>
          Zajmujemy się produkcją konstrukcji stalowych i elementów montażowych
          wykorzystywanych w fotowoltaice i branży budowlanej. Odpowiadamy na
          potrzeby rynku i stale poszerzamy ofertę, ponieważ wierzymy, że
          odnawialne źródła energii to przyszłość naszej planety.
        </p>
      </Article>
      <YellowTrapezoid ref={yellowHexRef} />
    </StyledSection>
  )
}
export default WhoSection
